import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import Alert from 'react-bootstrap/Alert';

function Dashboard() {


    return (
        <>
        <Header />
        <Sidebar />
    </>
    );
}

export default Dashboard;